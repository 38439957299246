export const theme = {
  primaryColor: '#10255D',
  secondaryColor: '#D6C26E',
  gray: '#DDDDDD',
  white: '#ffffff',
  black: '#000000',
  defaultHeaderColor: '#333333',
  disabledColor: '#888888',
  grayText: '#959595',
  desktopHeaderHeight: '80px',
  focusColor: '#4c9aff',
  linkColor: '#2f74ce',
  errorColor: '#e44100',
  negativeColor: '#cc432a',
  positiveColor: '#52b74d',
  defaultInputBackgroundColor: '#f5f5f5',
  mobileHeaderHeight: '60px',
  defaultFontColor: '#10255D',
  defaultFont: '"Baskerville", "Sans Serif"',
  defaultHeaderFontColor: '#10255D',
  defaultTransition: 'all 0.2s linear',
  defaultFontSize: '16px',
  defaultMobileFontSize: '14px',
}
